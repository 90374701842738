const searchDivDesktop = document.querySelector('.search-bar-appear');
const searchBtnDesktop = document.querySelector('.js-search-desktop');
const searchDivMobile = document.querySelector('.search-bar-appear-mobile');
const searchBtnMobile = document.querySelector('.search-mobile-button');
let icon = document.getElementById("icon");
let icon1 = document.getElementById("a");
let icon2 = document.getElementById("b");
let icon3 = document.getElementById("c");
let nav = document.getElementById('nav');
let blue = document.getElementById("blue");
let navMobile = document.querySelector('.nav-content-mobile');

if (searchBtnDesktop != null) {
   searchBtnDesktop.addEventListener('click', function() {
      if (searchDivDesktop.classList.contains('show')) {
         searchDivDesktop.classList.remove('show')
      } else {
         searchDivDesktop.classList.add('show')
      }
   });
}

if (searchBtnMobile != null) {
   searchBtnMobile.addEventListener('click', function() {
      if (searchDivMobile.classList.contains('show')) {
         searchDivMobile.classList.remove('show')
      } else {
         searchDivMobile.classList.add('show')
      }
   });
}

if (icon != null) {
   icon.addEventListener('click', function() {
      icon1.classList.toggle('a');
      icon2.classList.toggle('c');
      icon3.classList.toggle('b');
      if (navMobile.classList.contains('show')) {
         navMobile.classList.remove('show');
         document.body.style.overflowY = 'scroll';
      } else {
         navMobile.classList.add('show');
         document.body.style.overflowY = 'hidden';
      }
   });
}